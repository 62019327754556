import { styled } from '@linaria/react';

import { Spacings } from '@/styles/base/spacings';

export const RMStack = styled.div<{
  direction: 'column' | 'row';
  spacing: keyof typeof Spacings;
  center?: boolean;
  flex?: boolean;
}>`
  height: 100%;
  width: 100%;
  display: flex;
  flex: ${(props) => (props.flex ? 1 : 0)};
  flex-flow: ${(props) => props.direction};
  gap: ${(props) => `var(--spacing-${props.spacing})`};
  align-items: ${(props) => (props.center ? 'center' : 'unset')};
`;
